export const navLinks = [
  {
    label: 'Projects',
    pathname: '/',
    hash: '#project-1',
  },
  {
    label: 'About',
    pathname: '/',
    hash: '#details',
  },
  // {
  //   label: 'Articles',
  //   pathname: '/articles',
  // },
  {
    label: 'Contact',
    pathname: '/contact',
  },
];

export const socialLinks = [
  {
    label: 'ChevronRight',
    url: '/projects/euyo',
    icon: 'chevronRight',
  },
  {
    label: 'Email',
    url: '/contact',
    icon: 'send',
  },
  {
    label: 'Instagram',
    url: 'https://www.instagram.com/bingbongjon',
    icon: 'instagram',
  },
];
