import classNames from 'classnames';
import { useId } from 'hooks';
import './index.css';

function Monogram({ highlight, className, ...props }) {
  const id = useId();
  const clipId = `monogram-clip-${id}`;

  return (
    <svg aria-hidden="true" class="monogram" width="40" height="45" viewBox="0 0 40 45"><defs><clipPath id="monogram-clip-1"><path d="M31.24,3.5a4.85,4.85,0,0,1,3.08-1.41,5,5,0,0,1,3.23.84,5,5,0,0,1,.77,7.51A5,5,0,0,1,34.79,12c-.09.42-.25.83-.33,1.25-.06.22-.09.45-.16.67a.65.65,0,0,1,0,.13c-.07.4-.2.78-.3,1.17a46.5,46.5,0,0,1-3.77,9.52,36.62,36.62,0,0,1-5,7.15,38.81,38.81,0,0,1-5.56,5.08,53.16,53.16,0,0,1-10.39,6c-.15-.24-.25-.5-.39-.75A20.53,20.53,0,0,0,6.36,38.4a34.41,34.41,0,0,0-2.48-2.65c0-.06-.13-.1-.16-.17A41.33,41.33,0,0,0,10,33a34.14,34.14,0,0,0,6.41-4.22,29,29,0,0,0,5.13-5.6,27.31,27.31,0,0,0,4.12-9.15H7.8c-1.14,0-2.28,0-3.42,0-.87,0-1.73,0-2.6.08-.52,0-1,.09-1.57.14V6.44a22.94,22.94,0,0,0,2.73.27c1.39.07,2.78.07,4.18.07H29.84a5,5,0,0,1,1.4-3.28m3.17,1.06a2.53,2.53,0,0,0-2,1.68,2.38,2.38,0,0,0,0,1.58,2.59,2.59,0,0,0,1,1.25,2.46,2.46,0,0,0,1.81.33,2.54,2.54,0,0,0,1.87-1.7,2.39,2.39,0,0,0-.25-1.95A2.47,2.47,0,0,0,34.41,4.56Z"/><path d="M39.79,41.27c-.07-.14,0-.3,0-.45Z"/></clipPath></defs><rect clip-path="url(#monogram-clip-1)" width="100%" height="100%"></rect><g clip-path="url(#monogram-clip-1)"><rect class="monogram__highlight" width="100%" height="100%"></rect></g></svg>
  );
}

export default Monogram;
